export default {
  install(Vue, args) {
    String.prototype.format = function (args) {
      let result = this;
      if (arguments.length > 0) {
        if (arguments.length === 1 && typeof args === "object") {
          for (const key in args) {
            if (args[key] !== undefined) {
              const reg = new RegExp("({" + key + "})", "g");
              result = result.replace(
                reg,
                args[key] === null ? "" : String(args[key])
              );
            }
          }
        } else {
          for (let i = 0; i < arguments.length; i++) {
            if (arguments[i] !== undefined) {
              const reg = new RegExp("({)" + i + "(})", "g");
              result = result.replace(
                reg,
                arguments[i] === null ? "" : String(arguments[i])
              );
            }
          }
        }
      }
      return result;
    };

    String.prototype.getParameter = function (key) {
      const re = new RegExp(key + "=([^&]*)(?:&)?");
      return this.match(re) && this.match(re)[1];
    };

    Date.prototype.format = function (fmt = "yyyy-MM-dd hh:mm:ss") {
      const o = {
        "M+": this.getMonth() + 1, // 月份
        "d+": this.getDate(), // 日
        "h+": this.getHours(), // 小时
        "H+": this.getHours(), // 小时
        "m+": this.getMinutes(), // 分
        "s+": this.getSeconds(), // 秒
        "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds(), // 毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (this.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (const k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    };

    Date.prototype.getWeek = function () {
      const firstDate = new Date(this.getFullYear(), 0, 1);
      const sDate = new Date(
        this.getFullYear(),
        this.getMonth(),
        this.getDate()
      );
      let shiftDays = firstDate.getDay();
      if (shiftDays === 0) shiftDays = 7;
      return Math.ceil(((sDate - firstDate) / 86400000 + shiftDays) / 7);
    };

    Date.prototype.getWeekDates = function (sameMonth = false) {
      let dinx = this.getDay() - 1;
      const ct = this.getTime();
      const month = this.getMonth();
      if (dinx < 0) dinx = 6;
      const arr = [];
      for (let i = -dinx, l = 7 - dinx; i < l; i++) {
        const d = new Date(ct + i * 86400000);
        if (!sameMonth || d.getMonth() === month) arr.push(d);
      }
      return arr;
    };

    Date.prototype.getSystemTime = function () {
      return new Date(_sys_now);
    };
  },
};
