import axios from "axios";
import router from "@/router";
import store from "@/store";
import { Toast } from "vant";
import { getToken } from "@/utils/auth";
import config from "@/config";
import Cookies from "js-cookie";
import qs from "qs";

// 创建axios实例
const service = axios.create({
  timeout: config.timeout, // 请求超时时间
});

let canceller = {};

// response 拦截器
service.interceptors.request.use(
  (config) => {
    let urlMatches = /^@([a-zA-z]+):/gi.exec(config.url);
    if (urlMatches && urlMatches.length) {
      config.baseURL = window.config.api[urlMatches[1]];
      config.url = config.url.replace(urlMatches[0], "");
    } else {
      config.baseURL = window.config.api.default;
    }
    if (getToken()) {
      config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    if (config.cancellerId) {
      if (canceller[config.cancellerId]) canceller[config.cancellerId].cancel();
      canceller[config.cancellerId] = axios.CancelToken.source();
      config.cancelToken = canceller[config.cancellerId].token;
    }
    const arrayFormat = config.headers.arrayFormat || "indices";
    if (config.method === "get" && config.params instanceof Object) {
      for (let k in config.params) {
        if (
          config.params.hasOwnProperty(k) &&
          (config.params[k] === "" ||
            config.params[k] === null ||
            config.params[k] === undefined)
        ) {
          delete config.params[k];
        }
      }
      config.paramsSerializer = {
        serialize: (params) => {
          return qs.stringify(params, {
            allowDots: true,
            arrayFormat: arrayFormat,
          });
        },
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    if (response.config && response.config.cancellerId) {
      delete canceller[response.config.cancellerId];
    }
    return response.data;
  },
  (error) => {
    const cHandler =
      error && error.config ? error.config.customizeErrorHandle : false;
    if (axios.isCancel(error)) {
      return;
    }
    // 兼容blob下载出错json提示
    if (error.response && error.response.data) {
      if (
        error.response.data instanceof Blob &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        const reader = new FileReader();
        reader.readAsText(error.response.data, "utf-8");
        reader.onload = function (e) {
          const errorMsg = JSON.parse(reader.result).message;
          if (cHandler) {
            return Promise.reject(new Error(errorMsg));
          } else {
            Message.error({
              message: errorMsg,
              duration: 3000,
            });
          }
        };
      } else {
        let code = 0;
        try {
          code = error.response.data.status;
        } catch (e) {
          if (error.toString().indexOf("Error: timeout") !== -1) {
            Toast.fail({
              message: "网络请求超时",
              duration: 3000,
            });
            return Promise.reject(error);
          }
        }
        if (code) {
          if (code === 401) {
            store.dispatch("LogOut").then(() => {
              // 用户登录界面提示
              Cookies.set("point", 401);
              location.reload();
            });
          } else if (code === 403) {
            router.push({ path: "/401" });
          } else {
            const errorMsg = error.response.data.message;
            if (cHandler) {
              return Promise.reject(new Error(errorMsg));
            } else {
              if (errorMsg !== undefined) {
                Toast.fail({
                  message: errorMsg,
                  duration: 3000,
                });
              }
            }
          }
        } else {
          Toast.fail({
            message: "接口请求失败",
            duration: 3000,
          });
        }
      }
    } else {
      let errorMsg = "连接服务器失败，请稍候重试";
      if (cHandler) {
        return Promise.reject(new Error(errorMsg));
      } else {
        Toast.fail({
          message: errorMsg,
          duration: 3000,
        });
      }
    }
    return Promise.reject(error);
  }
);
export default service;
