import { login, getInfo, logout } from "@/api/signIn";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { dingtalkLogin } from "@/api/sso";

const user = {
  state: {
    token: getToken(),
    user: null,
    roles: [],
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const rememberMe = userInfo.rememberMe;
      return new Promise((resolve, reject) => {
        login(
          userInfo.username,
          userInfo.password,
          userInfo.code,
          userInfo.uuid
        )
          .then((res) => {
            setToken(res.token, rememberMe);
            commit("SET_TOKEN", res.token);
            setUserInfo(res.user, commit);
            // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
            commit("SET_LOAD_MENUS", true);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    DingtalkLogin({ commit }) {
      return new Promise((resolve, reject) => {
        if (!window.dd) reject(new Error("不支持钉钉登录"));
        window.dd.getAuthCode({
          corpId: config.dingtalk.corpId,
          onSuccess: function (auth) {
            if (auth?.code) {
              dingtalkLogin({
                authCode: auth.code,
              })
                .then((res) => {
                  setToken(res.token, false);
                  commit("SET_TOKEN", res.token);
                  setUserInfo(res.user, commit);
                  resolve();
                })
                .catch(reject);
            } else {
              reject(new Error("获取授权码失败"));
            }
          },
          onFail: function (err) {
            reject(new Error(err ? err.errmsg : "原因未知"));
          },
        });
      });
    },

    SSOLogin({ commit }, res) {
      return new Promise((resolve, reject) => {
        try {
          if (res) {
            setToken(res.token, false);
            commit("SET_TOKEN", res.token);
            setUserInfo(res.user, commit);
            // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
            commit("SET_LOAD_MENUS", true);
            resolve();
          } else {
            reject();
          }
        } catch (e) {
          reject();
        }
      });
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            setUserInfo(res, commit);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then((res) => {
            logOut(commit);
            resolve();
          })
          .catch((error) => {
            logOut(commit);
            reject(error);
          });
      });
    },
  },
};

export const logOut = (commit) => {
  commit("SET_TOKEN", "");
  commit("SET_ROLES", []);
  removeToken();
};

export const setUserInfo = (res, commit) => {
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  if (res.roles.length === 0) {
    commit("SET_ROLES", ["ROLE_SYSTEM_DEFAULT"]);
  } else {
    commit("SET_ROLES", res.roles);
  }
  commit("SET_USER", res.user);
};

export default user;
