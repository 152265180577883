import Cookies from "js-cookie";
import config from "../config";

const tokenKey = config.auth.tokenKey;

export function getToken() {
  if (window.dd) {
    return window.localStorage.getItem(tokenKey);
  }
  return Cookies.get(tokenKey);
}

export function setToken(token) {
  if (window.dd) {
    return window.localStorage.setItem(tokenKey, token);
  }
  return Cookies.set(tokenKey, token);
}

export function removeToken() {
  if (window.dd) {
    return window.localStorage.removeItem(tokenKey);
  }
  return Cookies.remove(tokenKey);
}
