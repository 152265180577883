<template>
  <router-view />
</template>

<script>
import { getLightColor, getDarkColor, HexToRgb } from "./utils/color";

export default {
  methods: {
    applyColor(c) {
      const sc = "#07c160";
      const wc = "#ff976a";
      const dc = "#ee0a24";
      const ic = "#909399";

      const cRgb = HexToRgb(c);
      const scRgb = HexToRgb(sc);
      const wcRgb = HexToRgb(wc);
      const dcRgb = HexToRgb(dc);
      const icRgb = HexToRgb(ic);

      const cssTexts = [
        `--color-primary: ${c}`,
        `--color-primary-rgb: ${cRgb.join(",")}`,
        `--color-success: ${sc}`,
        `--color-success-rgb: ${scRgb.join(",")}`,
        `--color-warning: ${wc}`,
        `--color-warning-rgb: ${wcRgb.join(",")}`,
        `--color-danger: ${dc}`,
        `--color-danger-rgb: ${dcRgb.join(",")}`,
        `--color-info: ${ic}`,
        `--color-info-rgb: ${icRgb.join(",")}`,
      ];

      const colorMaps = {
        primary: c,
        success: sc,
        warning: wc,
        danger: dc,
        info: ic,
      };

      const colorRgbMaps = {
        primary: cRgb,
        success: scRgb,
        warning: wcRgb,
        danger: dcRgb,
        info: icRgb,
      };

      const types = [
        { key: "primary", color: c },
        { key: "success", color: sc },
        { key: "warning", color: wc },
        { key: "danger", color: dc },
        { key: "info", color: ic },
      ];

      const round = function (val) {
        return Math.round(val * 100) / 100;
      };
      types.forEach((t) => {
        const rgb = colorRgbMaps[t.key];
        const grayLevel = 0.213 * rgb[0] + 0.715 * rgb[1] + 0.072 * rgb[2];
        // const base =
        //   this.theme === "light"
        //     ? 0.05 + (128 - grayLevel) / 2560
        //     : 0.05 - (128 - grayLevel) / 12800;
        const base = 0.05 + (128 - grayLevel) / 1000;
        console.log(t.key, grayLevel, base);

        for (let i = 1; i < 10; i++) {
          const light = getLightColor(t.color, i / 10);
          const dark = getDarkColor(t.color, i / 12);

          // const light = `oklch(from var(--color-${t.key}) calc(l * ${round(
          //   1 + base * i
          // )}) calc(c * ${round(1 - 0.05 * (i - 1))}) h)`;
          // const dark = `oklch(from var(--color-${t.key}) calc(l * ${round(
          //   1 - base * i
          // )}) c h)`;

          // if (this.theme === "light") {
          cssTexts.push(`--color-${t.key}-light-${10 - i}: ${light}`);
          cssTexts.push(`--color-${t.key}-dark-${i}: ${dark}`);
          colorMaps[`${t.key}Light${10 - i}`] = light;
          colorMaps[`${t.key}Dark${i}`] = dark;
          // } else {
          //   cssTexts.push(`--color-${t.key}-dark-${i}: ${light}`);
          //   cssTexts.push(`--color-${t.key}-light-${10 - i}: ${dark}`);
          //   colorMaps[`${t.key}Dark${i}`] = light;
          //   colorMaps[`${t.key}Light${10 - i}`] = dark;
          // }
          // } else {
          //   const light = `oklch(from var(--color-${t.key}) calc(l * ${round(
          //     1 - base * i
          //   )}) c h)`;
          //   const dark = `oklch(from var(--color-${t.key}) calc(l * ${round(
          //     1 + base * 2.5 * i
          //   )}) c h)`;
          //   cssTexts.push(`--color-${t.key}-light-${10 - i}: ${light}`);
          //   cssTexts.push(`--color-${t.key}-dark-${i}: ${dark}`);
          //   colorMaps[`${t.key}Dark${i}`] = slight;
          //   colorMaps[`${t.key}Light${10 - i}`] = sdark;
          // }
        }
      });

      const header = document.getElementsByTagName("head")[0];
      let style = header.querySelector(`#color`);
      if (!style) {
        style = document.createElement("style");
        style.type = "text/css";
        style.id = "color";
        header.appendChild(style);
      }
      for (let i = 0; i < style.childNodes.length; i++) {
        let el = style.childNodes[i];
        if (el) style.removeChild(el);
      }
      style.appendChild(
        document.createTextNode(`:root {${cssTexts.join(";")}}`)
      );

      window._colorMaps = colorMaps;
    },
  },
  mounted() {
    this.applyColor("#1989fa");
  },
};
</script>
<style>
body {
  background-color: var(--background-color-base);
  /* display: flex;
  flex-direction: column; */
}
</style>