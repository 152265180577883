import Vue from "vue";
import VueRouter from "vue-router";
import { Toast } from "vant";
import store from "@/store";
import { getToken } from "@/utils/auth"; // getToken from cookie

Vue.use(VueRouter);

const whiteList = ["/dev"];

const routes = [
  {
    path: "/",
    name: "Index",
    component: (resolve) => require(["@/views/index"], resolve),
  },
  {
    path: "/project",
    name: "Project",
    component: (resolve) => require(["@/views/project/index"], resolve),
    meta: { title: "项目总览" },
  },
  {
    path: "/project/detail/:id",
    name: "ProjectDetail",
    component: (resolve) => require(["@/views/project/detail"], resolve),
    meta: { title: "项目详情" },
  },
  {
    path: "/project/stage/detail/:stageId",
    name: "ProjectStageDetail",
    component: (resolve) => require(["@/views/project/stage/detail"], resolve),
    meta: {
      title: "阶段详情",
    },
  },
  {
    path: "/project/stage/detail/:stageId",
    name: "ProjectStageDetail",
    component: (resolve) => require(["@/views/project/stage/detail"], resolve),
    meta: {
      title: "阶段详情",
    },
  },
  // {
  //   path: "/project/task/:stageId",
  //   name: "ProjectTasks",
  //   component: (resolve) => require(["@/views/project/task/index"], resolve),
  //   meta: { title: "任务列表" },
  // },
  {
    path: "/project/risk/:projectId",
    name: "ProjectRisk",
    component: (resolve) => require(["@/views/project/risk/index"], resolve),
    meta: { title: "项目风险" },
  },
  {
    path: "/project/task/detail/:id",
    name: "ProjectTaskDetail",
    component: (resolve) => require(["@/views/project/task/detail"], resolve),
    meta: { title: "任务详情" },
  },
  {
    path: "/project/task/mine",
    name: "ProjectTaskMine",
    component: (resolve) => require(["@/views/project/task/mine"], resolve),
    meta: { title: "我的任务" },
  },
  {
    path: "/project/task/experiment/:taskId",
    name: "ProjectExp",
    component: (resolve) =>
      require(["@/views/project/experiment/index"], resolve),
    meta: { title: "实验记录" },
  },
  {
    path: "/project/task/addProgress/:taskId",
    name: "ProjectProgressAdd",
    component: (resolve) => require(["@/views/project/progress/add"], resolve),
    meta: { title: "新增进度记录" },
  },
  {
    path: "/project/task/progress/:taskId",
    name: "ProjectProgress",
    component: (resolve) =>
      require(["@/views/project/progress/index"], resolve),
    meta: { title: "进度记录" },
  },
  {
    path: "/project/task/progress/detail/:progressId",
    name: "ProjectTasksProgressDetail",
    component: (resolve) =>
      require(["@/views/project/progress/detail"], resolve),
    meta: { title: "进度记录详情" },
  },
  {
    path: "/project/task/risk/:taskId",
    name: "ProjectTasksRisk",
    component: (resolve) => require(["@/views/project/risk/index"], resolve),
    meta: { title: "任务风险" },
  },
  {
    path: "/project/risk/detail/:riskId",
    name: "ProjectRiskDetail",
    component: (resolve) => require(["@/views/project/risk/detail"], resolve),
    meta: { title: "风险记录详情" },
  },
  {
    path: "/project/experiment/detail/:id",
    name: "ProjectExperimentDetail",
    component: (resolve) =>
      require(["@/views/project/experiment/detail"], resolve),
    meta: { title: "实验记录详情" },
  },
  {
    path: "/testing/detail/:id",
    name: "TestingDetail",
    component: (resolve) => require(["@/views/testing/detail"], resolve),
    meta: { title: "检测详情" },
  },
];

if (window.config.env === "development") {
  routes.push({
    path: "/dev",
    name: "Dev",
    component: (resolve) => require(["@/views/dev"], resolve),
  });
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const login = () => {
    if (window.config.env === "development") {
      next("/dev");
    } else if (window.dd) {
      store
        .dispatch("DingtalkLogin")
        .then(() => {
          next();
        })
        .catch((err) => {
          Toast.fail("登录失败: " + err?.message);
        });
    } else {
      store.dispatch("LogOut").then(() => {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    }
  };

  if (to.meta.title) document.title = to.meta.title;
  const token = getToken();
  if (token) {
    // 已登录
    if (store.getters.roles.length === 0) {
      // 判断是否已获取用户信息，如未获取则获取用户信息
      store
        .dispatch("GetInfo")
        .then(() => {
          next();
        })
        .catch(() => {
          login();
        });
    } else {
      let title = "修实生物医药";
      if (to.meta?.title) {
        title = to.meta.title;
      }
      if (window.dd) {
        window.dd.setNavigationTitle({ title });
      } else {
        document.title = title;
      }
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      login();
    }
  }
});

export default router;
