import request from "@/utils/request";

export function dingtalkLogin(data) {
  return request({
    url: "api/dingtalkauth/h5/login",
    method: "post",
    data,
  });
}

export default {
  dingtalkLogin,
};
