import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import core from "./utils/core";

import * as dd from "dingtalk-jsapi";

import Vant from "vant";
import "vant/lib/index.css";

import "@/assets/styles/define.css";
import "@/assets/styles/index.scss";
import "@/assets/styles/base.scss";
import CountTo from "@/components/CountTo";
Vue.config.productionTip = false;
Vue.component("count-to", CountTo);
Vue.use(core);
Vue.use(Vant);

window.dd = dd;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
